import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  DEFAULT_CURRENCY,
  currency as getCurrency,
  Currencies,
} from '../../config/currency';
import createPersistedState from 'use-persisted-state';

const Container = styled.div`
  position: absolute;
  top: 15px;
  right: 16px;
  border: 1px solid #0094a5;
  width: 130px;
  z-index: 100;
  background: #fff;
`;

const CurrentCurrency = styled.button`
  position: relative;
  display: block;
  width: inherit;
  border: 0 none;
  outline: none;
  background: no-repeat 9px center;
  background-size: 20px 20px;
  padding: 5px 0 5px 36px;
  text-align: left;
  font-size: 16px;

  &.AUD {
    background-image: url('/images/currencies/aud.svg');
  }
  &.EUR {
    background-image: url('/images/currencies/euro.svg');
  }
  &.GBP {
    background-image: url('/images/currencies/gbp.svg');
  }
  &.NZD {
    background-image: url('/images/currencies/nzd.svg');
  }
  &.USD {
    background-image: url('/images/currencies/usa.svg');
  }
`;

const CurrencyMenuOpener = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  border-left: 1px solid #0094a5;
  background: url('/images/arrow-down.svg') no-repeat center center;

  &.open {
    background-image: url('/images/arrow-up.svg');
  }
`;

const CurrencyOptions = styled.div`
  border-top: 1px solid #0094a5;
`;

const CurrencyOptionItem = styled.button`
  display: block;
  width: 128px;
  border: 0 none;
  outline: none;
  background: no-repeat #fff 90px 7px;
  background-size: 20px 20px;
  padding: 6px 0 6px 26px;
  text-align: left;
  font-size: 16px;

  &.AUD {
    background-image: url('/images/currencies/aud.svg');
  }
  &.EUR {
    background-image: url('/images/currencies/euro.svg');
  }
  &.GBP {
    background-image: url('/images/currencies/gbp.svg');
  }
  &.NZD {
    background-image: url('/images/currencies/nzd.svg');
  }
  &.USD {
    background-image: url('/images/currencies/usa.svg');
  }

  &:hover,
  &:focus {
    background-color: #f3f3f3;
  }
`;

const useCurrency = createPersistedState('currency');

const CurrencyList = () => {
  const [activeCurrency, setActiveCurrency] = useCurrency<string>();
  const [open, setOpen] = useState(false);

  const selectCurrency = (curr: string) => () => {
    setActiveCurrency(curr);
    setOpen(false);
  };

  const toggleOpen = () => setOpen(!open);

  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);

  useEffect(() => {
    if (activeCurrency !== undefined) {
      setCurrency(getCurrency(activeCurrency));
    }
  }, [activeCurrency]);

  const items = Currencies.supported.map(({ code, symbol }) => (
    <CurrencyOptionItem
      key={code}
      onClick={selectCurrency(code)}
      className={currency.code === code ? `${code} active` : code}
    >
      {code} - {symbol}
    </CurrencyOptionItem>
  ));

  return (
    <Container>
      <CurrentCurrency className={currency.code} onClick={() => toggleOpen()}>
        {currency.code} - {currency.symbol}
        <CurrencyMenuOpener className={open ? 'open' : 'close'} />
      </CurrentCurrency>
      {open && <CurrencyOptions>{items} </CurrencyOptions>}
    </Container>
  );
};

export default CurrencyList;
