import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import formatSlug from '../../lib/slugFormatter';

const AdventureFilter = () => {
  const [filterSlug, setFilterSlug] = useState('/');

  // const strapiFilterOptions = useStaticQuery(graphql`
  //   query FilterOptions {
  //     allStrapiCategories {
  //       edges {
  //         node {
  //           Title
  //           Intro
  //           strapiId
  //           Order
  //           Key_Visual {
  //             publicURL
  //           }
  //         }
  //       }
  //     }
  //     allStrapiContinents {
  //       edges {
  //         node {
  //           Title
  //           strapiId
  //           Key_Visual {
  //             publicURL
  //           }
  //           Description
  //         }
  //       }
  //     }
  //     allStrapiCountries {
  //       edges {
  //         node {
  //           Title
  //           Description
  //           Continent {
  //             id
  //             Title
  //           }
  //           strapiId
  //           Key_Visual {
  //             publicURL
  //           }
  //           Intro
  //         }
  //       }
  //     }
  //   }
  // `);
  // const getDestinations = () => {
  //   const filterOptions = [];
  //   strapiFilterOptions.allStrapiContinents.edges
  //     .map(elem => elem.node)
  //     .sort((continent1, continent2) =>
  //       continent1.Title < continent2.Title ? -1 : 1
  //     )
  //     .map(continent => {
  //       filterOptions.push({
  //         title: continent.Title,
  //         slug: formatSlug(['destinations', continent.Title]),
  //       });

  //       strapiFilterOptions.allStrapiCountries.edges
  //         .map(elem => elem.node)
  //         .filter(country => country.Continent.id === continent.strapiId)
  //         .sort((country1, country2) =>
  //           country1.Title < country2.Title ? -1 : 1
  //         )
  //         .forEach(country => {
  //           filterOptions.push({
  //             title: `${country.Title}`,
  //             slug: formatSlug([
  //               'destinations',
  //               continent.Title,
  //               country.Title,
  //             ]),
  //           });
  //         });
  //       return continent;
  //     });
  //   return filterOptions;
  // };

  // const getCategories = () => {
  //   return strapiFilterOptions.allStrapiCategories.edges
  //     .map(entry => {
  //       return {
  //         title: entry.node.Title,
  //         slug: formatSlug([entry.node.Title]),
  //       };
  //     })
  //     .sort(
  //       (category1, category2) =>
  //         (category1.Order || 999) - (category2.Order || 999)
  //     );
  // };

  // const destinations = getDestinations();
  const destinations =
    [
      { title: 'Australia', slug: '/destinations/australia' },
      { title: 'Canada', slug: '/destinations/canada' },
      { title: 'Ireland', slug: '/destinations/ireland' },
      { title: 'Japan', slug: '/destinations/japan' },
      { title: 'New Zealand', slug: '/destinations/new-zealand' },
      { title: 'South Korea', slug: '/destinations/south-korea' },
      { title: 'UK', slug: '/destinations/uk' },
      { title: 'United States', slug: '/destinations/united-states' }
    ]


  // const categories = getCategories();
  const categories = [
    { title: 'Internships Abroad', slug: '/internships-abroad' },
    { title: 'Working Holidays', slug: '/working-holidays' },
    { title: 'Summer Camp', slug: '/summer-camp' }
  ]

  const selectFilter = e => {
    setFilterSlug(e.target.value);
  };

  return (
    <Quicksearch className="quicksearch relative md:absolute z-10 w-11/12 md:w-10/12 lg:w-8/12 bottom-32 md:-bottom-10 py-14 px-5 sm:py-10 sm:px-5 lg:p-10 bg-white rounded-3xl shadow-lg">
      <div className='grid grid-cols-12 gap-2'>
        <div className="form-grouppy-3 col-span-12 md:col-span-5">
          <Select
            className="select-field form-control quicksearch-destination w-full rounded-full h-10 border-2 border-grey_light"
            onChange={selectFilter}
          >
            <option key="empty" value="">
              Filter by destination
            </option>
            {destinations.map(destination => {
              return (
                <option key={destination.slug} value={destination.slug}>
                  {destination.title}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="form-group col-span-12 md:col-span-5">
          <Select
            className="select-field form-control quicksearch-interest w-full rounded-full h-10 border-2 border-grey_light"
            onChange={selectFilter}
          >
            <option value="">Filter by interest</option>
            {categories.map(category => {
              return (
                <option key={category.slug} value={category.slug}>
                  {category.title}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="col-span-12 md:col-span-2 flex">
          <CtaButton to={filterSlug} className="inline-flex w-full items-center h-10 px-3 xl:px-6 bg-blue rounded-full text-white text-xs font-semibold uppercase">
            <div className='inline-flex mx-auto align-middle'>
              <svg className='inline sm:hidden xl:inline mr-2' xmlns="http://www.w3.org/2000/svg" width="8" height="19.246" viewBox="0 0 10.623 19.246">
                <path id="Caminho_63" data-name="Caminho 63" d="M-3256.945-9565l8.916,8.916-8.916,8.916" transform="translate(3257.652 9565.707)" fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="2" />
              </svg>
              <span className='whitespace-nowrap flex items-center'>Let's go</span>
            </div>
          </CtaButton>
        </div>
      </div>
    </Quicksearch>
  );
};

const Quicksearch = styled.div`
  left: 50%;
  transform: translate(-50%, 0);
`;

const CtaButton = styled(Link)`
  &:hover {
    color: white !important;
  }
`;

const Select = styled.select`
  font-size: .875rem;
`;

export default AdventureFilter;
