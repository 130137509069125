import React from 'react';
import slugFormatter from '../../../lib/slugFormatter';
import styled from 'styled-components';

const Button = styled.a`
  padding: 10px 48px 10px 16px;
  text-transform: uppercase;
  font-size: 16px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
  position: relative;
  width: auto;
  -webkit-align-self: flex-end;
  align-self: flex-end;

  :link,
  :visited {
    text-decoration: none;
    font-weight: 500;
    background-color: #ffad01;
    color: #fff;
  }

  :after {
    content: '\f178';
    top: 8px;
    right: 16px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    color: #fff;
    font-size: 18px;
    position: absolute;
  }

  :active,
  :hover {
    padding-right: 56px;
    background-color: #fb607f;
  }
`;

const Container = styled.div`
  padding: 16px;
  line-height: 1.4em;
  position: relative;
  bottom: auto;
  left: auto;
  width: auto;
  overflow: hidden;
  background-color: #0094a5;
  color: #fff;
  margin-bottom: 16px;

  @media screen and (min-width: 960px) {
    background-color: #fff;
    color: #222;
    z-index: 10;
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 450px;
  }

  &.theme-usa {
    background-color: #08599b;
    color: #fff;

    ${Button} {
      :link,
      :visited {
        background-color: #f63933;
      }

      :active,
      :hover {
        background-color: #333;
      }
    }
  }
`;

const Box = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%


  background-color: inherit;
  color: inherit;
`;

export default ({ cta, theme, title, infoData }) => {
  return (
    <Container className={`theme-${theme}`}>
      <Box>
        <p>
          {cta.Text ||
            `Book your ${title} experience with ${
              infoData && infoData.deposit ? infoData.deposit : ''
            } today!`}
        </p>
        <Button
          href={
            cta.URL ||
            slugFormatter(['book-now', title])
          }
        >
          {cta.Button || 'BOOK NOW'}
        </Button>
      </Box>
    </Container>
  );
};
