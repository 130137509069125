import React from 'react';
import styled from 'styled-components';

const Video = styled.video`
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  object-fit: cover;

  @media screen and (min-width: 768px) {
    height: 350px;
    margin-top: -60px;
  }

  @media screen and (min-width: 960px) {
    margin-top: 0;
    position: absolute;
    height: 100%;
  }
`;

export default ({ url }) => {
  return (
    <Video playsInline autoPlay muted loop>
      <source src={url} type="video/mp4" />
    </Video>
  );
};
