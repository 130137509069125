import React from 'react';
import styled from 'styled-components';

const Image = styled.div`
  display: block;
  position: relative;
  /* height: 270px; */
  height: 100%;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position-x: center;
  z-index: 1;
  background-position-y: center;
   @media screen and (min-width: 768px) {
    margin-top: -62.5px;
    height: 350px;
  }
  @media screen and (min-width: 960px) {
    margin-top: 0;
    position: absolute;
    height: 100%;
  }
`;

export default ({ url }) => {
  return (
    <Image
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url('${url}')`,
        display: 'block',
        backgroundPosition: 'center',
        loading:'lazy',
      }}
    />
  );
};
