import React, { FunctionComponent } from 'react';
import Link from '../common/Link';
import styled from 'styled-components';
import CurrencyList from './currency-list';

interface Props {
  title?: string;
  cost?: string;
  theme?: string;
  duration?: string;
  eligibility?: string;
  navigation?: NavigationItem[];
}

const AtAGlance: FunctionComponent<Props> = ({
  title = 'At a Glance',
  cost,
  theme,
  duration,
  eligibility,
  navigation = [],
}) => {
  const costLink = getSlugForPageWithTitle({
    navigation,
    titles: ['Costs'],
  });
  const eligibilityLink = getSlugForPageWithTitle({
    navigation,
    titles: ['Visa', 'Eligibility'],
  });

  return (
    <Container className={`theme-${theme}`}>
      <h3>{title}</h3>
      <CurrencyList />
      {cost && (
        <ProgInfoRow title="Costs from" link={costLink}>
          {cost}
        </ProgInfoRow>
      )}
      {duration && (
        <ProgInfoRow title="Duration" link={eligibilityLink}>
          {duration}
        </ProgInfoRow>
      )}
      {eligibility && (
        <ProgInfoRow title="Eligibility" link={eligibilityLink}>
          {eligibility}
        </ProgInfoRow>
      )}
    </Container>
  );
};

interface ProgInfoRowProps {
  title: string;
  link?: string;
  children?: string;
}

const ProgInfoRow: FunctionComponent<ProgInfoRowProps> = ({
  link,
  title,
  children,
}) => {
  return (
    <Row className="row">
      <div>
        <Title>{title}</Title>
      </div>
      <div>
        {link && (
          <Link to={link} activeClassName="active" partiallyActive={true}>
            {children}
          </Link>
        )}
        {!link && <>{children}</>}
      </div>
    </Row>
  );
};

interface NavigationItem {
  title: string;
  to: string;
}

interface SlugRequest {
  navigation: NavigationItem[];
  titles: string[];
}

function getSlugForPageWithTitle({ navigation, titles }: SlugRequest) {
  if (!navigation) {
    return;
  }

  const matchingPage = navigation.find((page) => {
    for (const title of titles) {
      if (page.title.includes(title)) {
        return page;
      }
    }
    return false;
  });

  if (!matchingPage) {
    return;
  }

  return matchingPage.to;
}

const Container = styled.div`
  background: #fff;
  border-top: 8px solid #0094a5;
  border-bottom: 8px solid #0094a5;

  &.theme-usa {
    border-top-color: #f63933;
    border-bottom-color: #f63933;
  }
  padding: 16px 16px 16px 32px;
  width: 100%;
  margin-bottom: 32px;
  margin-top: 32px;
  position: absolute;
  bottom: -24px;
  right: 16px;
  @media screen and (max-width: 768px) {
    right: 0;
    bottom: 0;
    position: relative;
  }

  @media screen and (min-width: 768px) {
    z-index: 1;
  }

  @media screen and (min-width: 960px) {
    float: right;
    width: 380px;
    border-bottom-width: 0;
    margin-bottom: 0px;
  }
  @media screen and (min-width: 1440px) {
    width: 400px;
    padding-right: 0;
  }
`;

const Title = styled.h4`
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    flex: 1 1 40%;
    &:last-child {
      flex: 1 1 60%;
    }
  }
`;

export default AtAGlance;
