import React, { FunctionComponent } from 'react';
import AdventureFilter from './adventurefilter';
import AtAGlance from './AtAGlance';
import Cta from './cta';
import styled from 'styled-components';
import Image from './image';
import Video from './video';
import { ButtonDataSource } from '../Page/datasource';

interface Props {
  title?: string;
  theme?: string;
  cta?: ButtonDataSource[];
  showAdventureFilter?: boolean;
  backgroundUrl: string;
  infoData?: {};
  fullWidth?: boolean;
}

const HeroHeader: FunctionComponent<Props> = ({
  title = 'Working Adventures Worldwide',
  theme,
  cta,
  showAdventureFilter = false,
  backgroundUrl,
  infoData,
  fullWidth = false,
}) => {
  const isVideo = backgroundUrl.split('.').pop() === 'mp4';
  return (
    <Container className="hero" fullWidth={fullWidth}>
      {/* <Heading>
        <h1>{title}</h1>
      </Heading> */}
      {!isVideo && <Image url={backgroundUrl} />}
      {isVideo && <Video url={backgroundUrl} />}
      {showAdventureFilter && <AdventureFilter />}
      {cta && cta.length > 1 && <Cta theme={theme} cta={cta} title={title} infoData={infoData} />}
      {infoData && <AtAGlance theme={theme} {...infoData} />}
    </Container>
  );
};

interface ContainerProps {
  fullWidth: boolean;
}

const Container = styled.div<ContainerProps>`
  background-position-x: center;
  background-size: cover;
  position: relative;
  > {
  }
  height: 480px;
  margin-bottom: 160px;

  @media screen and (max-width: 767px) {
    /* min-height: 270px;
    margin-bottom: 32px; */
  }
  @media screen and (min-width: 768px) {
    /* margin-bottom: 32px; */
    min-height: 615px;
    background-position-y: center;
  }
  @media screen and (min-width: 960px) {
    margin-left: ${(props) => (props.fullWidth ? '0' : '250px')};
    /* height: 550px;
    margin-bottom: 0; */
  }
  @media screen and (min-width: 1440px) {
    /* height: 615px; */
  }
`;

const Heading = styled.div`
  background-color: #fff;
  padding: 20px 32px;
  display: inline-block;
  position: relative;
  height: 70px;

  h1 {
    display: block;
    line-height: 42px;
    font-size: 42px;
    font-family: proxima-nova, sans-serif;
    font-display: swap;

    @media screen and (max-width: 767px) {
      margin-top: 30px;
      font-size: 26px;
      line-height: 26px;
    }
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding: 0 16px 8px;
  }
`;

export default HeroHeader;
